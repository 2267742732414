<template>
  <base-projects
    :projects="rejectedProjects"
    :custom-headers="customHeaders"
    :custom-menu-options="menuOptions"
    :custom-menu-option-cb="menuOptionCallBack"
    :is-loading="!rejectedProjects.length && isLoading.rejectedProjects"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import BaseProjects from './BaseProjects';
import projectService from '../../services/projectService';

export default {
  components: { BaseProjects },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.project) {
      this.$refs.project.beforeRouteLeave(to, from, next);
    } else {
      next();
    }
  },
  props: ['projectId'],
  data() {
    return {
      menuOptions: [
        { name: 'Edit' },
        { name: 'Move to Pending' },
        { name: 'Notes' }
      ]
    };
  },
  computed: {
    ...mapGetters([
      'rejectedProjects',
      'company',
      'isLoading',
    ]),
    customHeaders() {
      const customHeaders = [
        { text: 'Submission date', value: 'createdAt', align: 'left', width: 160, type: 'date' },
        { text: 'Title', value: 'title', align: 'left', width: 180 },
        { text: 'Notes', value: 'notes', width: 100 },
        { text: 'Coaches', value: 'coachNames', width: 140 },
        { text: 'Creator', value: 'creator', align: 'left', width: 200 },
        { text: 'Project Unit', value: 'projectUnit', align: 'left', width: 160 },
        { sortable: false }
      ];

      if (this.company.features[FEATURE_NAME.CAMPAIGNS]) {
        customHeaders.splice(customHeaders.length - 1, 0, {
          text: 'Campaign',
          value: 'campaign',
          align: 'left',
          width: 180
        });
      }

      return customHeaders;
    }
  },
  methods: {
    menuOptionCallBack(name, project) {
      switch (name) {
        case 'Edit':
          this.$router.push({
            name: 'AdminManageProjectEdit',
            params: { id: project.id }
          });
          break;
        case 'Move to Pending':
          projectService.markProjectAsPending(project)
            .then(() => {
              this.$store.dispatch('showSnackBar', {
                text: 'The project was reactivated, it\'s now under Projects -> Pending'
              });
            });
          break;
        case 'Notes':
          this.$router.push({
            name: 'AdminManageProjectNotes',
            params: { id: project.id }
          });
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped>
  tr {
    cursor: pointer;
  }

  ::v-deep .disabled {
    color: gray;
  }
</style>
